/* eslint-disable react/no-danger */
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import SEO from "../components/seo";
import Layout from "../components/Layout";
import Tags from "../components/Tags";
import MarkdownContent from "../components/MarkdownContent";

import "../components/news.css";

export default function Template({
  data,
  location, // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter } = data.NewsPage;
  return (
    <div>
      <SEO
        title={frontmatter.title}
        pathname={location.pathname}
        keywords={frontmatter.tags}
      />
      <Layout>
        <div className="news__item">
          <div className="news__item-content">
            <Img
              className="news__item-content--image"
              fluid={
                frontmatter.image &&
                frontmatter.image.childImageSharp &&
                frontmatter.image.childImageSharp.fluid
              }
              alt={frontmatter.title}
            />
            <h1>{frontmatter.title}</h1>
            <MarkdownContent
              content={frontmatter.short_description}
              className="news__item-content--short"
            />
            <MarkdownContent content={frontmatter.description} />
            <div>
              {frontmatter.additional_pictures &&
                frontmatter.additional_pictures.map((pic) => {
                  return (
                    <Img
                      className="news__item-gallery--image"
                      fluid={
                        pic && pic.childImageSharp && pic.childImageSharp.fluid
                      }
                      alt={frontmatter.title}
                    />
                  );
                })}
            </div>
            <div className="news__item-content--date">
              Opublikowano: {frontmatter.date}
            </div>
          </div>
          <div className="separator" />
          {frontmatter.tags && (
            <div className="news__item--tags">
              <Tags
                taggedPages={data.TaggedPages.edges}
                targetPage={data.NewsPage.id}
              />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
}

export const pageQuery = graphql`
  query NewsPostById($id: String!) {
    NewsPage: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        date(formatString: "DD.MM.YYYY, HH:mm")
        short_description
        description
        tags
        additional_pictures {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    TaggedPages: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { regex: "/news-post|offer-page/" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            tags
          }
        }
      }
    }
  }
`;
